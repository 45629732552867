#main-page {
  display: flex;
}

#main-menu {
  display: flex;
  height: 100vh;
}

#app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background: white;
}
